import { createSlice } from "@reduxjs/toolkit";

export interface Data {
    [key:string]:string | number | boolean | any[];
}

interface InitialState {
    data:Data | null;
}

const initialState:InitialState = {
    data:null,
};

const checkoutSlice = createSlice({
    name:'checkout',
    initialState,
    reducers:{
       checkoutData:(state, action)=>{
        state.data = action.payload;
       }   
    }
});

export const { checkoutData } = checkoutSlice.actions;
export default checkoutSlice.reducer;