import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../service/http-common";
import alert from "../../utils/alert";
import { loader } from "react-global-loader";
// import { getBookingId } from "@/Store/modals";
// import { Data } from "@/Store/checkout";


interface BookingDayPassState {
  info:{
    bookingId:string | null;
  },
  isloading: boolean;
  error: string | null;
}
const initialState: BookingDayPassState = {
  info:{
    bookingId:null,
  },
  isloading: false,
  error: null,
};

export interface PostDaypass{
  comment?:string | null;
  booked_seats?:number | undefined;
  date?:string | null;
  day_passId?:number | undefined;
}

// Get booking day pass action
export const postBookingDayPass = createAsyncThunk(
  "bookingDayPass/postBookingDayPass",
  async (data:PostDaypass, thunkAPI) => {
  
    try {
      loader.show();
      const response = await http.post(`api/universal/day-pass/booking`, data);

      if (response.status === 201) {
        loader.hide();
        // thunkAPI.dispatch(getBookingId(response?.data?.bookingId));
        return response.data;
      } else {
        loader.hide();
        alert(response.data, "error");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      loader.hide();
      alert(error?.response?.data?.message, "error");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const bookingDayPassSlice = createSlice({
  name: "bookingDayPass",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postBookingDayPass.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(postBookingDayPass.fulfilled, (state, action) => {
        state.isloading = false;
        state.info = action.payload;
      })
      .addCase(postBookingDayPass.rejected, (state, action) => {
        state.isloading = false;
      });
  },
});
export default bookingDayPassSlice.reducer;
