import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useAppDispatch } from "../../store";

interface IData {
  id?: number;
  setSelectedDate: any;
  onChange: any;
  // setShowSlots?:any;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const Index = (data: IData) => {
  const dispatch = useAppDispatch();
  const [value, onChange] = useState<Value>(new Date());
  useEffect(() => {
    data?.setSelectedDate(value);
    // const handleOnMeetingRoomChange = () => {
    //   if (!!data?.id) {
    //      context?.key && dispatch(
    //         getBookSLots({
    //           id: data?.id,
    //           date: value,
    //         })
    //       ).then(() => {
    //         data?.setShowSlots(true);
    //       });
    //   }
    // };
    // !!data?.id && handleOnMeetingRoomChange();
  }, [value, dispatch, data]);

  return (
    <>
      <DatePicker
        onChange={onChange}
        value={value}
        className="my-2 sm:w-96 w-full p-2 border border-gray-color rounded-md"
        clearIcon={false}
        minDate={new Date()}
        // shouldCloseCalendar={data?.onChange()}
        onCalendarClose={data?.onChange}
      />
    </>
  );
};

export default Index;
