import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../service/http-common";
import alert from "../../utils/alert";
import { loader } from "react-global-loader";
// import { LeadType } from "@/utils/types/type";

interface Leads {
  phone: string;
  booking_type: string;
  location_id: number;
}

interface PrivateRoom {
  title:string;
  id:number;
}

interface LeadState {
  leads: Leads[];
  private_room?: PrivateRoom | null;
  isloading: boolean;
  error: string | null;
}
const initialState: LeadState = {
  leads: [],
  private_room:null,
  isloading: false,
  error: null,
};

// Create Lead action
export const createLead = createAsyncThunk(
  "leads/createLead",
  async (data:any, thunkAPI) => {
    try {
      loader.show();
      const response = await http.post("/api/leads/generate", data);

      if (response.status === 201) {
        loader.hide();
        alert('Lead Generated Successfully', "success");
        return response.data;
      } else {
        loader.hide();
        alert(response?.data, "error");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      loader.hide();
      alert(error?.response?.data?.message, "error");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const createLeadslice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    privateRoomDetail:(state, action)=>{
      state.private_room = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLead.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(createLead.fulfilled, (state, action) => {
        state.isloading = false;
        state.leads.push(action.payload);
      })
      .addCase(createLead.rejected, (state, action) => {
        state.isloading = false;
      });
  },
});
export const {privateRoomDetail} = createLeadslice.actions;
export default createLeadslice.reducer;
