import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../service/http-common";
import { loader } from "react-global-loader";
// import alert from "@/utils/alert";

interface AreasState {
  areas: string[];
  isloading: boolean;
  error: string | null;
}
const initialState: AreasState = {
  areas: [],
  isloading: false,
  error: null,
};

// Get Areas action
export const getAreaByCity = createAsyncThunk(
  "areas/getAreaByCity",
  async (data:any, thunkAPI) => {
    try {
      loader.show();
      const response = await http.get(
        `/api/public/locations?city=${data?.city}`
      );

      if (response.status === 200) {
        loader.hide();
        return response.data;
      } else {
        loader.hide();
        // alert(response.data, "error");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      loader.hide();
    //   alert(error?.response?.data?.message, "error");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const areasSlice = createSlice({
  name: "areas",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAreaByCity.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getAreaByCity.fulfilled, (state, action) => {
        state.isloading = false;
        state.areas = action.payload?.data;
      })
      .addCase(getAreaByCity.rejected, (state, action) => {
        state.isloading = false;
      });
  },
});
export default areasSlice.reducer;
