import { useContext, useState } from "react";
import { AuthContext } from "../context/UserContext";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Modal from "./Modal";
import { dataContext } from "../context/DataContext";
import { useAppDispatch, useAppSelector } from "../store";
import { postVerifyPhoneNumber } from "../store/verifyphone";
import alert from "../utils/alert";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    phone: yup
      .string()
      .min(8, "Enter a contact number between 8-15 digits")
      .max(15, "Enter a contact number between 8-15 digits")
      .matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Enter Valid Contact Number"
      )
      .required()
      .typeError("PleaseEnter Valid Contact Number"),
    // password: yup.string().min(5).required(),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const Login = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [activeState, setActiveState] = useState(0);
  const [otp, setOtp] = useState<string>("");
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: "",
      // password: "",
    },
  });
  const data = useContext(AuthContext);
  const userData = useContext(dataContext);
  const {data:checkout} = useAppSelector((store)=>store.checkout)
  // const onSubmit: SubmitHandler<FormData> = async (submitData) => {
  // await data?.login({ ...submitData });
  // userData?.setOpenLoginModal(false);
  // reset();
  // };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;
    // console.log(key);

    // currentOtpNumber = index;
    // if (key === "Backspace") setActiveOTPIndex(currentOtpNumber - 1);
    if (
      Number.isNaN(+key) &&
      key !== "Backspace" &&
      key !== "Delete" &&
      key !== "Enter"
    )
      e.preventDefault();
  };

  const submit1 = () => {
    dispatch(
      postVerifyPhoneNumber({
        phone_no: getValues("phone") as string,
      })
    )
      .then((res) => {
        if (res?.meta?.requestStatus === "fulfilled") {
          // console.log("res", res);
          setActiveState(activeState + 1);
          // setActiveOTPIndex(0);
        } else {
          // console.log("else res", res);
          // dispatch(setPhoneNumber(res?.meta?.arg?.phone_no));
          // setvalid(true);
          // dispatch(openLoginModal(false));
          // dispatch(openRegisterModal(true));
        }
      })
      .catch((err) => {
        // console.log("eer", err);
      });
  };

  const submit2 = async () => {
    await data?.login({
      phone_no: getValues("phone") as string,
      otp: otp,
    })?.then(()=>{
      if (checkout !== null) {
        userData?.setOpenLoginModal(false);
        alert("You have Successfully Logged in", "success");
        navigate(`/checkout/${checkout?.uuid}`);
      } else {
        userData?.setOpenLoginModal(false);
        alert("You have Successfully Logged in", "success");
      }
    });
    userData?.setOpenLoginModal(false);
    setOtp("");
    setActiveState(0);
    reset();
  };

  return (
    <>
      {props?.open ? (
        <div
          id="loginModal"
          tabIndex={-1}
          aria-hidden="true"
          className="fixed flex items-center top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative w-full max-w-2xl sm:w-full sm:h-full max-h-full mx-auto grid items-center">
            <div className="relative  rounded-lg shadow">
              <div className=" space-y-6 rounded-lg">
                <section
                  id="login-pages"
                  className="bg-login-page w-full object-cover relative p-4 flex justify-center items-center rounded-lg"
                >
                  <div className="login-form p-5 gradient-tw max-w-md z-50 rounded-lg mx-auto shadow-lg">
                    <div className="flex flex-row justify-between w-full items-start">
                      <h1 className="text-2xl font-semibold text-center text-white mb-3 w-full">
                        Login to Your Account
                      </h1>
                      <div className="relative z-50">
                        <button
                          type="button"
                          className="text-white bg-transparent rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                          data-modal-hide="loginModal"
                          onClick={() => userData?.setOpenLoginModal(false)}
                        >
                          <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </div>
                    </div>
                    <div className="md:flex-1 mt-5">
                      {activeState === 0 && (
                        <form action="#" onSubmit={handleSubmit(submit1)}>
                          <div className="flex flex-col space-y-5">
                            <div className="flex flex-col space-y-1 mb-4">
                              <label
                                htmlFor="phone"
                                className="text-sm font-semibold text-white"
                              >
                                Phone Number
                              </label>
                              {/* <Controller
                              name="email"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <input
                                  // type="email"
                                  id="email"
                                  {...field}
                                  autoFocus
                                  className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                                  aria-invalid={errors.email ? true : false}
                                />
                              )}
                            /> */}
                              <Controller
                                name="phone"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <input
                                    type="tel"
                                    {...field}
                                    // onKeyDown={()=>setvalid(false)}
                                    className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                                    autoFocus
                                    onInput={(e) => {
                                      const inputValue = (
                                        e.target as HTMLInputElement
                                      ).value;
                                      (e.target as HTMLInputElement).value =
                                        inputValue.replace(
                                          /[^0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+/g,
                                          ""
                                        );
                                    }}
                                    // placeholder="Enter your phone number"
                                  />
                                )}
                              />
                              {errors.phone && (
                                <p className="text-red-500 pt-2">
                                  Invalid credentials
                                </p>
                              )}
                            </div>
                            <div className="mt-3">
                              <button
                                // data-modal-target="popup-modal"
                                // data-modal-toggle="popup-modal"
                                type="submit"
                                className="text-center w-full px-4 py-2 text-lg font-semibold text-black transition-colors duration-300 main-bg rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                      {activeState === 1 && (
                        <form action="#" onSubmit={handleSubmit(submit2)}>
                          <div className="flex flex-col space-y-5">
                            <div className="flex flex-col space-y-1">
                              <label
                                htmlFor="otp"
                                className="text-sm font-semibold text-white"
                              >
                                Enter Otp
                              </label>
                            </div>
                            <input
                              type="text"
                              className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                              value={otp}
                              onKeyDown={(e) => handleKeyDown(e)}
                              onChange={(e) => {
                                let currentOtpNumber = e.target.value;
                                setOtp(currentOtpNumber.slice(0, 6));
                              }}
                              autoFocus
                            />
                            <div className="flex flex-row mt-3 gap-2">
                              <button className="text-center w-full px-4 py-2 text-lg font-semibold text-black transition-colors duration-300 main-bg rounded-md shadow hover:bg-blue-300 focus:outline-none focus:ring-blue-100 focus:ring-4">
                                Back
                              </button>
                              <button
                                data-modal-target="popup-modal"
                                data-modal-toggle="popup-modal"
                                type="submit"
                                className="text-center w-full px-4 py-2 text-lg font-semibold text-black transition-colors duration-300 main-bg rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
                              >
                                Log in
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                  {/* <div className="overlay absolute top-0 bottom-0 right-0 left-0 opacity-80 gradient-tw rounded-lg"></div> */}
                </section>
              </div>
            </div>
          </div>
          <div className="overlay-main absolute top-0 bottom-0 right-0 left-0 bg-dark opacity-40"></div>
        </div>
      ) : null}

      <Modal open={userData?.openModal as boolean} />
    </>
  );
};

export default Login;
