import React, { useContext, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../store'
import Moment from 'react-moment';
import { formatTimeDuration } from '../utils/formatTimeDuration';
import { AuthContext } from '../context/UserContext';
import { getAvailableCredits } from '../store/availableCredits';
import alert from '../utils/alert';
import { checkoutData } from '../store/checkout';
import { dataContext } from '../context/DataContext';
import { postBookingDayPass } from '../store/daypass';
import Modal from './Modal';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
  const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const contextData = useContext(dataContext);   
    const {data} = useAppSelector((store)=>store.checkout);
    const {availableCredits} = useAppSelector((store)=>store.availableCredits);
    const userContext = useContext(AuthContext);
    const {user} = userContext;

    useEffect(() => {
        dispatch(getAvailableCredits()).then((res)=>{
          if (res.meta.requestStatus==='rejected') {
            navigate('/');
          }
          
        });
      }, [dispatch, navigate]);
    
      const handleConfirmBooking = async () => {
        if (data?.type === "meeting room") {
            await contextData?.setBookingSlots({
                bookingData: data?.bookingData,
            })
             await contextData?.handleBookingSlot(data?.bookingData).then(()=>{
               contextData?.handleMeetingSlots({
                  date: data?.date,
                  id: data?.id,
                });
              }).catch((err)=>{
              alert((err.message as any)?.message as string[], "error");
             }); 

            // await dispatch(
            //   bookSlots({
            //     bookingData: data?.bookingData,
            //     date: data?.date,
            //     id: data?.id,
            //   })
            // ).then((res) => {
            //   if (res?.meta?.requestStatus === "fulfilled") {
            //     dispatch(openBookModal(true));
            //     dispatch(checkoutData(null));
            //   } else {
            //     alert((res.payload as any)?.message as string[], "error");
            //   }
            // });
        }
        if (data?.type === "day pass") {
            await dispatch(postBookingDayPass(data?.bookingData as any)).then((res) => {
              if (res?.meta?.requestStatus === "fulfilled") {
                contextData?.setOpenModal(true);
                contextData?.setBookingId(res?.payload?.bookingId);
              } else {
                alert((res.payload as any)?.message as string[], "error");
              }
            });
        }
      };
  return (
    <>
    <section id="payment-detail-banner" className="py-20 bg-light-gray">
        <div className="container mx-auto">
          <h2 className="text-black-color text-4xl font-bold relative text-center">
            Checkout Detail
          </h2>
        </div>
      </section>
      {/* <!-- Payment Detail Banner End --> */}

      {/* <!-- Payment Detail Area Start --> */}
      {data !== null ? (
        <section id="pay-detail-area" className="py-10">
          <div className="container mx-auto">
            <div className="detail-area grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="booking-detail-payment bg-white rounded-xl shadow-main p-5 h-fit">
                <div className="book-head">
                  <h4 className="text-2xl font-bold">Booking Details</h4>
                  <hr className="mt-3 text-light-gray" />
                </div>

                <div className="booking-text mt-3">
                  <p>
                    We will use those details to share your booking information
                  </p>
                </div>

                <div className="user-detail grid grid-cols-1 md:grid-cols-2 gap-6 mt-5">
                  <div className="det-box">
                    <h6>Full Name</h6>
                    <h4 className="font-bold text-xl">{user?.name}</h4>
                  </div>

                  <div className="det-box">
                    <h6>Email Address</h6>
                    <h4 className="font-bold text-xl">{user?.email}</h4>
                  </div>

                  <div className="det-box">
                    <h6>Mobile Number</h6>
                    <h4 className="font-bold text-xl">{user?.phone_no}</h4>
                  </div>

                  <div className="det-box">
                    <h6>Date</h6>
                    <h4 className="font-bold text-xl">
                      <Moment format="DD-MM-YYYY">
                        {data?.date as string}
                      </Moment>
                    </h4>
                  </div>
                  {data?.slots && (
                    <>
                      <div className="det-box">
                        <h6>Duration</h6>
                        <h4 className="font-bold text-xl">
                          {formatTimeDuration(data?.duration as number * (data?.slots as any[])?.length)}
                          {/* {data?.duration as number * (data?.slots as any[])?.length} Min */}
                        </h4>
                      </div>
                      <div className="det-box">
                        <h6>Slots</h6>
                        <span className="flex flex-wrap">
                          {(data?.slots as any[])?.length > 0 && (
                            <>
                              <p className="text-sm block w-fit rounded-full px-2 py-1 mr-2 mb-2">
                                <Moment format="hh:mm A">
                                  {(data?.slots as any[])?.[0]?.start}
                                </Moment>{" "}
                                -{" "}
                                <Moment format="hh:mm A">
                                  {
                                    (data?.slots as any[])?.[
                                      (data?.slots as any[])?.length - 1
                                    ]?.end
                                  }
                                </Moment>
                              </p>
                            </>
                          )}
                        </span>
                      </div>
                    </>
                  )}
                  {data?.seats && (
                    <div className="det-box">
                      <h6>Number of Seats</h6>
                      <h4 className="font-bold text-xl">{data?.seats}</h4>
                    </div>
                  )}
                </div>

                <div className="confirm-book-btn">
                  <button
                    className="block w-full px-3 py-2 mt-8 rounded-lg text-center text-xl main-bg"
                    onClick={handleConfirmBooking}
                  >
                    Confirm Booking
                  </button>
                </div>
              </div>

              <div className="booking-office-address bg-white rounded-xl shadow-main p-5 h-fit">
                <div className="address-head">
                  <h4 className="text-2xl font-bold capitalize">
                    {data?.name}
                  </h4>
                  <p className="mt-1 capitalize">{data?.address}</p>
                </div>

                <div className="address-detail mt-4">
                  <div className="add-box flex justify-between items-center mb-2">
                    <h6>Pass Type</h6>
                    <h4 className="font-bold text-xl capitalize">
                      {data?.type}
                    </h4>
                  </div>
                  <div className="add-box flex justify-between items-center mb-2">
                    <h6>Credits Required</h6>
                    <h4 className="font-bold text-xl">
                      {contextData?.settings?.currency?.symbol}{" "}
                      {data?.credit_required}
                    </h4>
                  </div>
                  <div className="add-box flex justify-between items-center mb-2">
                    <h6>Available Credits</h6>
                    <h4 className="font-bold text-xl">
                      {contextData?.settings?.currency?.symbol}{" "}
                      {availableCredits?.credits}
                    </h4>
                  </div>
                  <div className="add-box flex justify-between items-center mb-2 capitalize">
                    <h6>{data?.type==='meeting room' ? 'Cost Per Meeting Room Per Slot' : 'Cost Per Day Pass'}</h6>
                    <h4 className="font-bold text-xl">
                      {contextData?.settings?.currency?.symbol} 
                      {data?.per_cost}
                    </h4>
                  </div>
                  <hr className="mt-3 mb-3 text-light-gray" />

                  <div className="add-box flex justify-between items-center mb-2">
                    <h4 className="font-bold text-xl">
                      Payable Amount <br />
                      <span className="text-sm font-medium">
                        Inclusive of all taxes.
                      </span>
                    </h4>
                    <h4 className="font-bold text-xl">
                      {contextData?.settings?.currency?.symbol}{" "}
                      {data?.credit_required}
                    </h4>
                  </div>

                  {/* <div className="pay-amount">
                  <a
                    href="#"
                    className="block px-3 py-2 bg-dark-red mt-8 rounded-lg text-white-color text-center text-xl w-fit ml-auto"
                  >
                    Pay Now
                  </a>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          <section id="pay-detail-area" className="py-10">
            <p className="text-center">No Selected space yet</p>
          </section>
        </>
      )}
      <Modal open={contextData?.openModal as boolean}/>
    </>
  )
}

export default Checkout;