import { createContext, useState, useEffect, useContext } from "react";
import { setAuthToken, http } from "../service/http-common";
import { dataContext } from "./DataContext";
import { useAlert } from "./AlertContext";
import { useAppDispatch, useAppSelector } from "../store";
import { verifyOtpNumber } from "../store/verifyphone";

export type UserDataType = {
  id: number;
  role: string[];
  email: string;
  name: string;
  phone_no: string;
};

export type ErrCallbackType = (err: { [key: string]: string }) => void;

export interface IFormInputs {
  phone_no: string;
  otp: string;
  roles?: string[];
}

export type AuthValuesType = {
  loading: boolean;
  setLoading: (value: boolean) => void;
  isInitialized: boolean;
  user: UserDataType | null | undefined;
  setUser: (value: UserDataType | null) => void;
  setIsInitialized: (value: boolean) => void;
  login: (params: IFormInputs, errorCallback?: ErrCallbackType) => any;
  error?: string | null;
  setError?: () => void;
  // isLoggedIn: boolean;
};

const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  // isLoggedIn: false,
};

export const AuthContext = createContext(defaultProvider);
type Props = {
  children: React.ReactNode;
};

const UserContext = ({ children }: Props) => {
  const contextData = useContext(dataContext);
  const { data } = useAppSelector((store) => store.checkout);
  const dispatch = useAppDispatch();
  const { setAlert } = useAlert();
  const slotDataContext = useContext(dataContext);

  const [user, setUser] = useState<any | null | undefined>(
    defaultProvider.user
  );
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);
  const [isInitialized, setIsInitialized] = useState<boolean>(
    defaultProvider.isInitialized
  );
  // const [isLoggedIn, setIsLoggedIn] = useState(defaultProvider.isLoggedIn);

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      setIsInitialized(true);
      const storedToken = JSON.parse(
        window.localStorage.getItem("collabzilla-userToken") as string
      );
      await setAuthToken(storedToken);
      if (storedToken) {
        setLoading(true);
        await http.get(`/api/user/me`).then(async (res: any) => {
          if (res?.status === 200) {
            setUser({ ...res?.data });
            window.localStorage.setItem(
              "collabzilla-me",
              JSON.stringify({ ...res?.data })
            );
            setLoading(false);
            // setIsLoggedIn(true);
          } else {
            localStorage.removeItem("collabzilla-me");
            localStorage.removeItem("collabzilla-userToken");
            setUser(null);
            setLoading(false);
            // setIsLoggedIn(false);
          }
        });
      } else {
        localStorage.removeItem("collabzilla-me");
        localStorage.removeItem("collabzilla-userToken");
        setLoading(false);
      }
    };
    initAuth();
  }, []);

  const handleLogin = async (
    params: IFormInputs,
    errorCallback?: ErrCallbackType
  ) => {
    try {
      // const res: any = await http.post(`/api/user/login`, params,{
      //   headers:{
      //     'Content-Type': 'application/json',
      //   }
      // });
      // if (res?.status === 201) {
      //   window.localStorage.setItem(
      //     "collabzilla-userToken",
      //     JSON.stringify(res?.data)
      //   );
      const res = await dispatch(
        verifyOtpNumber({
          ...params,
        })
      );
      if (res?.meta?.requestStatus === "fulfilled") {
        window.localStorage.setItem(
          "collabzilla-userToken",
          JSON.stringify(res?.payload)
        );
        setAuthToken(res?.payload as string);
        await http.get(`/api/user/me`).then(async (res: any) => {
          setUser({ ...res?.data });
          await window.localStorage.setItem(
            "collabzilla-me",
            JSON.stringify(res?.data)
          );

          // setIsLoggedIn(true);
          // await slotDataContext?.handleBookingSlot(slotDataContext?.bookingSlots);
          // slotDataContext?.setOpenModal(true);
        });
      } else {
        if (errorCallback) errorCallback(res?.payload);
      }
    } catch (error: any) {
      setAlert(error?.response?.data?.message, "error");
    }
  };

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    // isLoggedIn,
  };
  return (
    <>
      <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
    </>
  );
};

export default UserContext;
