import React from "react";
import { Navigate } from "react-router-dom";

interface IProps {
  children: React.ReactNode;
}

const ProtectedRoutes = ({ children }: IProps) => {
  return !!JSON.parse(
    window.localStorage.getItem("collabzilla-userToken") as string
  ) && !!JSON.parse(window.localStorage.getItem("collabzilla-me") as string) ? (
    <>
    {children}
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

export default ProtectedRoutes;
