import { useState, useContext, useEffect } from "react";
import { dataContext } from "../context/DataContext";
import { useParams } from "react-router-dom";
import Spinner from "./Spinner";
import { useAppDispatch, useAppSelector } from "../store";
import { getLocationById } from "../store/locationById";
import MeetingRoom from './meeting-room';
import DayPass from './day-pass';
import PrivateOffice from './private-office';

const tabsArray = ["Private-Office", "Meeting-room", "Day-Pass"];

const Page2 = () => {
  const { locationById } = useAppSelector((store) => store.locationById);
  const dispatch = useAppDispatch();
  const { locationId } = useParams();
  const data = useContext(dataContext);
  const [showMeeting, setShowMeeting] = useState<Boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string | null>(
    "Private-Office"
  );
  

  useEffect(() => {
    dispatch(
      getLocationById({
        id: locationId as string,
        category: selectedTab as string,
      })
    );
  }, [locationId, dispatch, selectedTab]);

  useEffect(() => {
    setShowMeeting(true);
    data?.handleMeetingInfo(locationId);
  }, [locationId]);
  return (
    <>
      <section id="booking" className="py-24">
        <div className="container mx-auto">
          <h2 className="text-3xl font-semibold text-dark">Booking Detail</h2>
          <hr className="mt-3 opacity-100" />
          {data?.isLoading ? (
            <Spinner />
          ) : (
            <>
              <div className="booking-area mt-3 bg-white rounded">
                {/* <!-- Room Tabs Start --> */}
                <div className="room-tabs">
                  <div className="text-sm font-medium text-center text-gray-500">
                    <ul className="flex flex-wrap -mb-px">
                      {tabsArray?.map((tab, i) => (
                        <li className="mr-2 w-fit" key={i}>
                          <button
                            className={`w-fit mt-2 room inline-block px-4 py-2 text-lg font-medium rounded text-black border capitalize ${
                              tab === selectedTab ? "room-active" : ""
                            }`}
                            onClick={async () => {
                              setShowMeeting(true);
                              setSelectedTab(tab);
                            }}
                          >
                            {tab?.split("-").join(" ")}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <!-- Room Tabs End --> */}
              </div>

              {/* <!-- Room Detail Start --> */}
              {showMeeting && locationById?.category==='Meeting-room' && <MeetingRoom locationById={locationById} selectedTab={selectedTab} />}
              {showMeeting && locationById?.category==='Day-Pass' && <DayPass locationById={locationById} selectedTab={selectedTab} />}
              {showMeeting && locationById?.category==='Private-Office' && <PrivateOffice locationById={locationById} selectedTab={selectedTab} />}
            </>
          )}
          {/* <!-- Room Detail End --> */}
        </div>
      </section>
    </>
  );
};

export default Page2;
