import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../service/http-common";
import alert from "../../utils/alert";
import { loader } from "react-global-loader";

interface AvailableCredits {
  credits:number;
}

interface AvailableCreditsState {
    availableCredits: AvailableCredits;
  isloading: boolean;
  error: string | null;
}
const initialState: AvailableCreditsState = {
  availableCredits: {
    credits:0,
  },
  isloading: false,
  error: null,
};

// Get available credits action
export const getAvailableCredits = createAsyncThunk(
  "availableCredits/getAvailableCredits",
  async (data, thunkAPI) => {
    try {
      loader.show()
      const response = await http.get("/api/members/credits");

      if (response.status === 200) {
        loader.hide();
        return response.data;
      } else {
        loader.hide();
        alert(response.data, "error");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      loader.hide();
      alert(error?.response?.data?.message, "error");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const availableCreditsSlice = createSlice({
  name: "availableCredits",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableCredits.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getAvailableCredits.fulfilled, (state, action) => {
        state.isloading = false;
        state.availableCredits = action.payload;
      })
      .addCase(getAvailableCredits.rejected, (state, action) => {
        state.isloading = false;
      });
  },
});
export default availableCreditsSlice.reducer;
