import axios from "axios";

export const http = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers:{
        'Content-Type': 'application/json',
    }
})

export const setAuthToken = (token :string | null) =>{
    
    if (token) {
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
} 

export const setBusinessKeyToHeader = (key :string | null) =>{
    
    if (key) {
        http.defaults.headers.common['business-key'] = key;
    }
}   