import { createContext, useState, useContext } from 'react';

interface IProps{
    children:React.ReactNode;
}

interface IAlertContext {
    text:string;
    type:string;
    setAlert:(text:string, type:string)=>void;
}

const ALERT_TIME = 3000;
const initialState = {
  text: '',
  type: '',
};

export const alertContext = createContext<IAlertContext>({
  ...initialState,
  setAlert: () => {},
});

const AlertProvider = ({ children }:IProps) => {
  const [text, setText] = useState('');
  const [type, setType] = useState('');

  const setAlert = (text:string, type:string) => {
    setText(text);
    setType(type);

    setTimeout(() => {
      setText('');
      setType('');
    }, ALERT_TIME);
  };

  return (
    <alertContext.Provider
      value={{
        text,
        type,
        setAlert,
      }}
    >
      {children}
    </alertContext.Provider>
  );
};

export const useAlert = () => useContext(alertContext);


export default AlertProvider;