import React, { useContext, useState, useEffect } from "react";
import Moment from "react-moment";
import { dataContext } from "../context/DataContext";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../context/AlertContext";
import Modal from "./Modal";
import Login from "./Login";
import alert from "../utils/alert";
import Spinner from "./Spinner";
import { AuthContext } from "../context/UserContext";
import moment from "moment";
import { useAppDispatch } from "../store";
import { checkoutData } from "../store/checkout";

const Slots = (props: any) => {
  const dispatch = useAppDispatch();
  const { setAlert } = useAlert();
  const navigate = useNavigate();
  const data = useContext(dataContext);
  const userContext = useContext(AuthContext);
  const [initialSlotArray, setInitialSlotArray] = useState<any>([]);
  const [selectedSlots, setSelectedSlots] = useState<any>([]);
  const [finalselectedSlots, setFinalSelectedSlots] = useState<any>([]);
  const [startSlot, setStartSlot] = useState<any>(null);
  const [endSlot, setEndSlot] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<Array<any>>([]);

  useEffect(() => {
    setFinalSelectedSlots([]);
    setSelectedSlots([]);
    setEndSlot(null);
    setStartSlot(null);
  }, [props?.selectedDate]);

  //* Effect to get Backend Data and set into UseState[array]
  useEffect(() => {
    if (data?.meetingSlots) setInitialSlotArray([...data?.meetingSlots]);
  }, [data?.meetingSlots]);

  //* Handle on click slots
  const handleSelectedSlot = (id: number, index: number) => {
    // check the items and create An array TimeSlot for selected slots
    if (selectedSlots.includes(id)) {
      const arr: any = [...selectedSlots];
      const index = arr.indexOf(id);
      arr.splice(index, 1);
      setSelectedSlots(arr);
    } else {
      setSelectedSlots([...selectedSlots, id]);
    }
    // Checkpoints To get Start And End index value for range-slots
    if (selectedSlots.length === 0 || index <= startSlot) {
      setStartSlot(index);
      if (index <= startSlot) {
        setEndSlot(null);
      }
    } else if (selectedSlots.length > 0 && index >= startSlot) {
      setEndSlot(index);
    }

    // check If user again click on same item
    if (index === endSlot) {
      setEndSlot(null);
    } else if (index === startSlot) {
      setStartSlot(null);
      setEndSlot(null);
      setSelectedSlots([]);
    }
  };

  //* Use Effect for create a Range-slot array with [timeslot 'Array' dependency ]

  useEffect(() => {
    // helping array
    const hlp: any = [...initialSlotArray];
    // Logic For range-slots
    if (data?.meetingSlots) {
      data?.meetingSlots?.forEach((item: any, index: number) => {
        // check 1st select item
        if (index === startSlot) {
          hlp.splice(index, 1, {
            booked: item.booked,
            id: item.id,
            selected: true,
            start: item.start,
            end: item?.end,
          });
        } else if (index > startSlot && index <= endSlot) {
          // check start and end index  to give range
          hlp.splice(index, 1, {
            booked: item.booked,
            id: item.id,
            selected: true,
            start: item.start,
            end: item?.end,
          });
        } else if (!startSlot && !endSlot) {
          // check if start and end index is null
          hlp.splice(index, 1, {
            booked: item.booked,
            id: item.id,
            selected: false,
            start: item.start,
            end: item?.end,
          });
        } else {
          hlp.splice(index, 1, {
            booked: item.booked,
            id: item.id,
            selected: false,
            start: item.start,
            end: item?.end,
          });
        }
      });
      setInitialSlotArray(hlp);
      const slot = hlp
        .filter((item: any) => {
          if (item.selected) {
            return item;
          }
        })
        .map((item: any) => {
          return item.id;
        });
      setFinalSelectedSlots([...slot]);
    }
  }, [selectedSlots]);

  useEffect(() => {
    const filteredItem = (data?.meetingSlots as any[])?.filter((item) =>
      finalselectedSlots.includes(item?.id)
    );
    setSelectedItem([...filteredItem]);
  }, [finalselectedSlots, data?.meetingSlots]);

  useEffect(() => {
    selectedItem?.map((item) => {
      if (item.booked) {
        setStartSlot(null);
        setEndSlot(null);
        setSelectedSlots([]);
        alert("You Have Selected Wrong Slot Range.", "error");
      }
    });
  }, [selectedItem]);

  const submitData = async () => {
    const bookingData = {
      comment: " ",
      slotsId: finalselectedSlots,
      meeting_roomsId: props?.universal_resource_defination?.id,
      date: `${moment(props?.selectedDate).format("YYYY-MM-DD")}`,
    };
    await data?.setBookingSlots(bookingData);
    dispatch(
      checkoutData({
        bookingData,
        type: "meeting room",
        uuid: props?.uuid,
        credit_required: finalselectedSlots?.length * props?.resource_price,
        per_cost: props?.resource_price,
        date: `${moment(props?.selectedDate).format("YYYY-MM-DD")}`,
        id: props?.universal_resource_defination?.id,
        name: props?.universal_resource_defination?.public_title,
        address: props?.address,
        slots: selectedItem,
        duration: props?.universal_resource_defination?.slot_duration,
      })
    );
    if (
      !!JSON.parse(
        window.localStorage.getItem("collabzilla-userToken") as string
      ) &&
      !!JSON.parse(window.localStorage.getItem("collabzilla-me") as string)
    ) {
      navigate(`/checkout/${props?.uuid}`);
      // await data?.handleBookingSlot(bookingData);
      // data?.setOpenModal(true);
      // data?.handleMeetingSlots({
      //   date: new Date(props.selectedDate).toISOString(),
      //   id: props?.selectedMeetingRoom,
      // });
      setFinalSelectedSlots([]);
      setSelectedSlots([]);
      setEndSlot(null);
      setStartSlot(null);
    } else {
      // navigate("/login");
      data?.setOpenLoginModal(true);
    }
  };

  return data?.slotError === null ? (
    <>
      <hr className="mt-4 mb-2" />
      <h2 className="text-xl font-semibold mb-2">Select Slot</h2>
      <ul className="flex flex-wrap -mb-px">
        {data?.isLoading ? (
          <Spinner />
        ) : (
          <>
            {initialSlotArray?.length === 0 ? (
              <>
                <p>No Slots Available</p>
              </>
            ) : (
              initialSlotArray?.map((slot: any, i: any) => (
                <li className="mr-2" key={slot?.id}>
                  <button
                    className={`w-fit slot-btn inline-block px-3 py-2 text-md font-medium text-black border rounded mt-2 ${
                      slot?.selected ? "slot-active" : ""
                    } ${slot?.booked ? "link-disabled" : ""}`}
                    onClick={() => handleSelectedSlot(slot?.id, i)}
                    disabled={slot?.booked ? true : false}
                  >
                    <Moment format="hh:mm A">{slot?.start}</Moment> -{" "}
                    <Moment format="hh:mm A">{slot?.end}</Moment>
                  </button>
                </li>
              ))
            )}
          </>
        )}
      </ul>

      <hr className="mt-4 mb-2" />

      <h2 className="text-xl font-semibold mb-2">Total Estimated Price</h2>
      <h3 className="main-text text-2xl font-bold">
        {data?.settings?.currency?.symbol}{" "}
        {finalselectedSlots?.length * props?.resource_price}
      </h3>

      <button
        data-modal-target={
          !!JSON.parse(
            window.localStorage.getItem("collabzilla-userToken") as string
          ) &&
          !!JSON.parse(window.localStorage.getItem("collabzilla-me") as any)
            ? "popup-modal"
            : "loginModal"
        }
        data-modal-toggle={
          !!JSON.parse(
            window.localStorage.getItem("collabzilla-userToken") as string
          ) &&
          !!JSON.parse(window.localStorage.getItem("collabzilla-me") as any)
            ? "popup-modal"
            : "loginModal"
        }
        className={`px-3 py-2 mt-4 w-full text-center block rounded font-semibold text-lg ${
          initialSlotArray?.length === 0 || selectedSlots?.length === 0
            ? "link-disabled"
            : "main-bg"
        }`}
        onClick={submitData}
        disabled={
          initialSlotArray?.length === 0 || selectedSlots?.length === 0
            ? true
            : false
        }
      >
        Book Now
      </button>
      <Login open={data?.openLoginModal} />
    </>
  ) : (
    <>
      <p className="py-2 primary-text capitalize font-semibold">
        {data?.slotError}
      </p>
    </>
  );
};

export default Slots;
