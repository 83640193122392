import { http, setAuthToken } from '../../service/http-common';
import alert from '../../utils/alert';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loader } from "react-global-loader";



interface VerifyPhoneNumberState {
  phone: string | null ;
  isloading: boolean;
  error: string | null;
}
const initialState: VerifyPhoneNumberState = {
  phone:null,
  isloading: false,
  error: null,
};


interface VerifyPhoneNumberType{
  phone_no?:string;
  otp:string;
}

interface NewUser{
  company_name?:string | null;
  email:string;
  name:string;
  phone_number?:string;
}
interface PostVerifynumber{
  phone_no:string;
}
// Get verify number action
export const postVerifyPhoneNumber = createAsyncThunk(
  "verifyPhoneNumber/postVerifyPhoneNumber",
  async (data:PostVerifynumber, thunkAPI) => {
    try {
   loader.show();
      const response = await http.post("api/user/login/otp", data);

      if (response.status === 201) {
        loader.hide();
        alert(`${response.data}`,'success');
        return response.data;

      } else {
        loader.hide()
        alert(response.data,'error')
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      loader.hide();
      alert(error?.response?.data?.message,'error')
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const verifyOtpNumber = createAsyncThunk("verifyPhoneNumber/verifyOtpNumber", async (data:VerifyPhoneNumberType, thunkAPI) =>{
  try {
 loader.show();
    const response = await http.post("api/user/login/otp/verify", data);

    if (response.status === 201) {
      setAuthToken(response.data);
      loader.hide();
      return response.data;

    } else {
      loader.hide();
      alert(response.data,'error')
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (error: any) {
    loader.hide();
    alert(error?.response?.data?.message,'error')
    return thunkAPI.rejectWithValue(error.response.data);
  }
})

// verify new user
export const verifyRegisterUser = createAsyncThunk("verifyPhoneNumber/verifyRegisterUser", async (data:NewUser, thunkAPI) =>{
  try {
    loader.show();
    const response = await http.post("/api/public/member/registration", data);

    if (response.status === 201) {
      loader.hide();
      return response.data;

    } else {
      loader.hide();
      alert(response.data,'error')
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (error: any) {
    loader.hide();
    alert(error?.response?.data?.message,'error')
    return thunkAPI.rejectWithValue(error.response.data);
  }
})

// register otp verification

export const verifyOtpRegisterUser = createAsyncThunk("verifyPhoneNumber/verifyOtpRegisterUser", async (data:VerifyPhoneNumberType, thunkAPI) =>{
  try {
    loader.show();
    const response = await http.post("/api/public/member/otp/verify", data);

    if (response.status === 201) {
      loader.hide();
      return response.data;

    } else {
      loader.hide()
      alert(response.data,'error')
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (error: any) {
    loader.hide();
    alert(error?.response?.data?.message,'error')
    return thunkAPI.rejectWithValue(error.response.data);
  }
})

const verifyPhoneNumberSlice = createSlice({
  name: "verifyPhoneNumber",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postVerifyPhoneNumber.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(postVerifyPhoneNumber.fulfilled, (state, action) => {
        state.isloading = false;
        state.phone = action.payload?.data;
      })
      .addCase(postVerifyPhoneNumber.rejected, (state, action) => {
        state.isloading = false;
      });
  },
});
export default verifyPhoneNumberSlice.reducer;
