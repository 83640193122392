import Page1 from "./components/Page1";
import DataContext from "./context/DataContext";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Page2 from "./components/Page2";
import UserContext from "./context/UserContext";
import AlertProvider from "./context/AlertContext";
import Alert from "./components/Alert";
import { Provider } from "react-redux";
import { store } from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkout from "./components/Checkout";
import ProtectedRoutes from "./components/PrivateRoutes";

import { LoaderContainer } from "react-global-loader";
import Spinner from "./components/Spinner";

function App() {
 const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Alert />
          <Page1 />
        </>
      ),
    },
    {
      path: "/booking/:locationId",

      element: (
        <>
          <Alert />
          <Page2 />
        </>
      ),
    },
    {
      path: "/checkout/:locationId",
      element: (
        <>
          <ProtectedRoutes>
            <Alert />
            <Checkout />
          </ProtectedRoutes>
        </>
      ),
    },
  ]);
  return (
    <>
    <LoaderContainer>
      <Spinner/>
    </LoaderContainer>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        style={{
          zIndex: 999999,
        }}
      />
      <Provider store={store}>
        <AlertProvider>
          <DataContext>
            <UserContext>
              <RouterProvider router={router} />
            </UserContext>
          </DataContext>
        </AlertProvider>
      </Provider>
    </>
  );
}

export default App;
