import React, { createContext, useState, useEffect } from "react";
import { http, setBusinessKeyToHeader } from "../service/http-common";
import { useAlert } from "./AlertContext";
import { loader } from "react-global-loader";
interface IDataContext {
  cities: string[];
  // area:string[];
  location: any;
  info: Array<any>;
  handleCity: (city: string) => Promise<any>;
  handleInfo: (data: any) => Promise<any>;
  slotError: string | null;
  meetingInfo: any;
  meetingSlots: Array<any>;
  handleMeetingInfo: any;
  handleMeetingSlots: any;
  setSlotError: any;
  setBookingSlots: any;
  handleBookingSlot: (booking: any) => Promise<any>;
  bookingSlots: any;
  openModal: boolean;
  setOpenModal: any;
  bookingId: number | null;
  openLoginModal: boolean;
  setOpenLoginModal: any;
  isLoading: boolean;
  setLoading: any;
  settings: any;
  setBookingId:any;
}

interface IChildrenProps {
  children: React.ReactNode;
}

export const dataContext = createContext<IDataContext | null>(null);

const DataContext = ({ children }: IChildrenProps) => {
  const { setAlert } = useAlert();
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationInfo, setLocationInfo] = useState([]);
  const [meetingInfo, setMeetingInfo] = useState([]);
  const [meetingSlots, setMeetingSlots] = useState([]);
  const [slotError, setSlotError] = useState<string | null>(null);
  const [bookingSlots, setBookingSlots] = useState<any | null>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [bookingId, setBookingId] = useState<number | null>(null);
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [businessKeys, setBusinessKeys] = useState<string | null>(null);
  const [settings, setSettings] = useState<any>({});

  useEffect(() => {
    loader.show();
    if (!!process.env.REACT_APP_BUSINESS_KEY) {
      setBusinessKeys(process.env.REACT_APP_BUSINESS_KEY);
      setBusinessKeyToHeader(process.env.REACT_APP_BUSINESS_KEY);
      loader.hide();
    }else {
      http
        .get(`api/public/business/keys`)
        .then((res) => res.data)
        .then((data) => {
          setBusinessKeyToHeader(data?.data?.[0]);
          setBusinessKeys(data?.data?.[0]);
          // setLoading(false);
          loader.hide();
        });
    }
  }, []);
  useEffect(() => {
    // setLoading(true);
    if (!!businessKeys) {
      loader.show()
      http
        .get(`api/public/${businessKeys}/cities`)
        .then((res) => res.data)
        .then((data) => {
          setCities(data?.data);
          // setLoading(false);
          loader.hide();
        })
        .catch((err) =>{ setAlert(err?.response?.data?.message, "error"); loader.hide()});
    }
  }, [setAlert, businessKeys]);

  useEffect(() => {
    if (!!businessKeys) {
      loader.show();
      http
        .get(`/api/business-setting/public`)
        .then((res) => res.data)
        .then((data) => {
          setSettings(data?.data);
          loader.hide()
        });
    }
  }, [businessKeys]);

  // useEffect(()=>{
  //   http.get(`/api/public/locations?city=chandigarh}`)
  // },[]);

  const handleCityChange = async (city: string) => {
    loader.show()
    await http
      .get(`/api/public/locations?city=${city}`)
      .then((res) => res.data)
      .then((data) => {setLocations(data?.data); loader.hide()})
      .catch((err) => {setAlert(err?.response?.data?.message, "error"); loader.hide()});
  };

  const setLocationChange = async (data: any) => {
    loader.show();
    await http
      .get(
        `api/universal/business/location?city=${data?.city}&area=${data?.location}`
      )
      .then((res) => res.data)
      .then((data) => {setLocationInfo(data?.data); loader.hide()})
      .catch((err) => {setAlert(err?.response?.data?.message, "error"); loader.hide()});
  };

  const meetingInfos = async (uuid: string) => {
    // setLoading(true);
    loader.show();
    await http
      .get(`/api/public/location/${uuid}/meeting-rooms`)
      .then((res) => res.data)
      .then((data) => {
        setMeetingInfo(data?.data);
        // setLoading(false);
        loader.hide()
      })
      .catch((err) =>{ setAlert(err?.response?.data?.message, "error"); loader.hide()});
  };

  const handleMeetingSlots = async (data: any) => {
    loader.show()
    await http
      .get(
        `/api/universal/slots?meeting_roomsId=${data?.id}&date=${data?.date}`
      )
      .then((res) => res.data)
      .then((data) => {setMeetingSlots(data?.data); loader.hide()})
      .catch((err) => {setSlotError(err?.response?.data?.message); loader.hide()});
  };

  const handleBookingSlot = async (booking: any) => {
    loader.show()
    await http
      .post(`/api/universal/meeting-room/booking`, booking, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data)
      .then(async (data) => { 
        if (data?.status === 201) {
          setBookingId(data?.bookingId);
          setOpenModal(true);
          loader.hide();
        }
      })
      .catch((err) => {setAlert(err?.response?.data?.message, "error");loader.hide()});
  };

  const value = {
    cities: cities,
    location: locations,
    info: locationInfo,
    meetingInfo: meetingInfo,
    meetingSlots: meetingSlots,
    slotError: slotError,
    handleCity: handleCityChange,
    handleInfo: setLocationChange,
    handleMeetingInfo: meetingInfos,
    handleMeetingSlots: handleMeetingSlots,
    setSlotError: setSlotError,
    setBookingSlots: setBookingSlots,
    handleBookingSlot: handleBookingSlot,
    bookingSlots: bookingSlots,
    openModal,
    setOpenModal,
    bookingId,
    setOpenLoginModal,
    openLoginModal,
    isLoading,
    setLoading,
    settings,
    setBookingId,
  };
  return (
    <>
      <dataContext.Provider value={value}>{children}</dataContext.Provider>
    </>
  );
};

export default DataContext;
