import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import areas from "./areas";
import locationById from "./locationById";
import checkout from "./checkout";
import availableCredits from "./availableCredits";
import daypass from "./daypass";
import lead from "./lead";

export const store = configureStore({
  reducer: {
    areas,
    locationById,
    checkout,
    availableCredits,
    daypass,
    lead,
  },
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector:TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;