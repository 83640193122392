import moment from "moment";
export function formatTimeDuration(timeDuration: any) {
  let time = moment.utc(
    moment.duration(timeDuration, "minutes").asMilliseconds()
  );

  const minutes = time.minutes();
  const hours = time.hours();
  const hourFormatStr = hours === 1 ? "hour" : "hours";
  const minuteFormatStr = minutes === 1 ? "minute" : "minutes";
  if (!time.minutes()) {
    return time.format(`H [${hourFormatStr}]`);
  }else if(!(!!hours)){
    return time.format(`mm [${minuteFormatStr}]`);
  }
  return time.format(`H [${hourFormatStr}] mm [${minuteFormatStr}]`);
}