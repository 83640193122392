import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../service/http-common";
import { loader } from "react-global-loader";

export interface PrivateOfficeType {
  id: number;
  images: string[];
  resources: {
    title: string;
    resource_type: string;
  };
  description: string;
  resource_price: number;
}

interface DaypassType {
  id: number;
  images: string[];
  resources: {
    title: string;
    resource_type: string;
  };
  price_per_seat: number;
  description: string;
  resource_price: number;
}

// export interface LocationByID{
//   uuid:string;
//   address_city:string;
//   seating_capacity:number | null;
//   name:string;
//   id:number;
//   facilities:Facilities[]
//   area:string;
//   details:string | null;
//   meeting_rooms:MeetingRoomType[]
//   day_pass:DaypassType[] | null;
//   location_area:string[]
//   location_assets:assetsType[];
//   private_office:PrivateOfficeType[] | null;
//   address:string;
//   Private_Office_credit:number | null
//   Day_Pass_credit:number | null
// }

interface LocationByIdState {
  locationById: null | any;
  isloading: boolean;
  error: string | null;
}
const initialState: LocationByIdState = {
  locationById: null,
  isloading: false,
  error: null,
};

// Get location by id action
export const getLocationById = createAsyncThunk(
  "locationById/getLocationById",
  async (data: { id: string; category: string }, thunkAPI) => {
    try {
      loader.show();
      const response = await http.get(
        `/api/universal-definition/${data?.id}/resources?page=1&records_per_page=20&category=${data?.category}`
      );

      if (response.status === 200) {
        loader.hide();
        return response.data;
      } else {
        loader.hide();
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      loader.hide();
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const locationByIdSlice = createSlice({
  name: "locationById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocationById.pending, (state, action) => {
        state.isloading = true;
      })
      .addCase(getLocationById.fulfilled, (state, action) => {
        state.isloading = false;
        state.locationById = action.payload;
      })
      .addCase(getLocationById.rejected, (state, action) => {
        state.isloading = false;
      });
  },
});
export default locationByIdSlice.reducer;
