import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createLead } from "../store/lead";
import { useAppDispatch } from "../store";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Minimum three characters")
    .max(18, "Can't exceed more than 18 characters")
    .required("Name is a required field"),
  email: yup.string().email().required("Enter a valid email address"),
  phone: yup
    .string()
    .min(8, "Enter a contact number between 8-15 digits")
    .max(15, "Enter a contact number between 8-15 digits")
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      "Enter Valid Contact Number"
    )
    .typeError("Please Enter Valid Contact Number"),
  notes: yup
    .string()
    .required("Message is required field")
    .min(10, "Minimum ten characters")
    .max(100, "Can't exceed 100 characters"),
  // interest: yup.string().required("Please Select your interest"),
  // location_id: yup.string().required("Please Select your location"),
});
const Lead = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const submithandler = (data: any) => {
    const leaddata = {
      ...data,
      Lead_source: "Plugin",
      location_id: props?.location_id,
      date:moment(props?.selectedDate).format('MM-DD-YYYY'),
    };
    dispatch(createLead(leaddata)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate("/");
        reset();
      }
    });
  };
  return (
    <>
      <hr className="mt-4 mb-2" />
      <form className="mt-4" onSubmit={handleSubmit(submithandler)}>
        <div className="flex flex-wrap sm:flex-nowrap sm:gap-6 w-full">
          <div className="relative w-full my-4">
            <label
              htmlFor="name"
              className="px-4 py-1 text-xs rounded-xl z-10 -top-4 left-5 font-medium"
            >
              Your name
            </label>
            <input
              {...register("name")}
              type="text"
              id="text"
              className="shadow-sm w-full text-base font-medium relative py-4 px-5 rounded-2xl border"
            />
            <p className="text-red-600 text-xs font-semibold">
              {errors?.name?.message}
            </p>
          </div>
          <div className="relative w-full my-4">
            <label
              htmlFor="email"
              className="px-4 py-1 text-xs rounded-xl z-10 -top-4 left-5 font-medium"
            >
              Your email
            </label>
            <input
              {...register("email")}
              type="email"
              id="email"
              className="shadow-sm w-full text-base font-medium relative py-4 px-5 rounded-2xl border"
            />
            <p className="text-red-600 text-xs font-semibold">
              {errors?.email?.message}
            </p>
          </div>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap sm:gap-6  w-full">
          <div className="relative w-full my-4">
            <label
              htmlFor="phone"
              className="px-4 py-1 text-xs rounded-xl z-10 -top-4 left-5 font-medium"
            >
              Phone
            </label>
            <input
              {...register("phone")}
              type="text"
              id="phone"
              className="shadow-sm w-full text-base font-medium relative py-4 px-5 rounded-2xl border"
            />
            <p className="text-red-600 text-xs font-semibold">
              {errors?.phone?.message}
            </p>
          </div>

          <div className="relative w-full my-4">
            <label
              htmlFor="phone"
              className="px-4 py-1 text-xs rounded-xl z-10 -top-4 left-5 font-medium"
            >
              Company Name
            </label>
            <input
              type="text"
              id="phone"
              placeholder="(Optional)"
              className="w-full text-base font-medium relative py-4 px-5 rounded-2xl border"
            />
          </div>
          {/* <div className="relative w-full my-4">
                    <span className="px-4 py-1 rounded-xl bg-light-gray text-gray-color absolute z-10 -top-4 left-5 text-xs">
                      Area Of Interest
                    </span>
                    <div className="flex items-center relative">
                      <select
                        {...register("interest")}
                        id="office-space"
                        className="focus:ring-main-color-red focus:border-main-color-red  shadow-sm w-full text-base font-medium relative py-4 px-5 rounded-2xl text-gray-color border-gray-color border"
                      >
                        <option value="">Choose your interest</option>
                        {BookingType &&
                          BookingType.map((data, i) => (
                            <option value={data?.title} key={data?.id}>
                              {data?.title}
                            </option>
                          ))}
                      </select>
                    
                    </div>
                  <p className="text-dark-red text-xs font-semibold">
                        {errors?.interest?.message}
                      </p>
                  </div> */}
        </div>
        <div className="flex flex-wrap sm:flex-nowrap sm:gap-6 w-full">
          {/* <div className="relative w-full my-4">
                    <span className="px-4 py-1 rounded-xl bg-light-gray text-gray-color absolute z-10 -top-4 left-5 text-xs">
                      Location
                    </span>
                    <div className="flex items-center relative">
                      <select
                        {...register("location_id")}
                        id="office-space"
                        className="focus:ring-main-color-red focus:border-main-color-red  shadow-sm w-full text-base font-medium relative py-4 px-5 rounded-2xl text-gray-color border-gray-color border capitalize"
                        onFocus={() =>
                          context?.key && dispatch(getLocationsConcise())
                        }
                      >
                          <option value="">Select your location</option>
                        {locations &&
                          locations.map((data, i) => (
                            <option value={data.id} key={data.id}>
                              {data.name}
                            </option>
                          ))}
                      </select>
                      
                    </div>
                  <p className="text-dark-red text-xs font-semibold">
                        {errors?.location_id?.message}
                      </p>
                  </div> */}
          <div className="relative w-full my-4">
            <label
              htmlFor="phone"
              className="px-4 py-1 text-xs rounded-xl z-10 -top-4 left-5 font-medium"
            >
              Message
            </label>
            <textarea
              {...register("notes")}
              // type="text"
              rows={5}
              id="message"
              className="shadow-sm w-full text-base font-medium relative py-4 px-5 rounded-2xl border resize-none"
            />
            <p className="text-red-600 text-xs font-semibold">
              {errors?.notes?.message}
            </p>
          </div>
        </div>
        <div className="pt-5">
          <button
            type="submit"
            className="py-4 px-5 text-base font-medium text-center text-white-color rounded-lg bg-dark-red sm:w-fit main-bg"
          >
            Request For Call
          </button>
        </div>
      </form>
    </>
  );
};

export default Lead;
