import { useContext } from "react";
import { dataContext } from "../context/DataContext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store";
import { checkoutData } from "../store/checkout";
import success from '../images/success.png';

interface IModalProps {
  open: boolean;
}

const Modal = ({ open }: IModalProps) => {
  const dispatch = useAppDispatch();
const navigate = useNavigate();
  const userData = useContext(dataContext);
  return (
    <>
      {/* <!-- SUCCESS MODAL START --> */}
      {/* <!-- Main modal --> */}
      {open ? (
        <div
          id="popup-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="fixed flex top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative w-full max-w-2xl max-h-full m-auto">
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 z-50">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Booking Successful
                </h3>
                {/* <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="popup-modal"
                  onClick={() => userData?.setOpenModal(false)}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button> */}
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6">
                <img
                  src={success}
                  alt="success"
                  className="mx-auto"
                />

                <h4 className="text-center text-2xl font-bold primary-text">
                  Congratulation! You have successfully booked your space.
                </h4>
                <h4 className="text-center text-xl font-bold">
                  Your booking id is #{userData?.bookingId}
                </h4>
              </div>
              {/* <!-- Modal footer --> */}
              <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600 justify-center">
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="primary-bg text-white px-4 py-3 rounded"
                  onClick={() =>{ userData?.setOpenModal(false); navigate('/'); dispatch(checkoutData(null)) }}
                >
                  Go to Home
                </button>
              </div>
            </div>
          </div>
          <div className="overlay-main absolute top-0 bottom-0 right-0 left-0 bg-dark opacity-40"></div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;