import React from 'react'

const Spinner = () => {
  return (
    <>
    <div className='flex justify-center items-center pt-4'>
    <div className="custom-loader"></div>
    </div>
    </>
  )
}

export default Spinner;