import { useAlert } from "../context/AlertContext";

const Alert = () => {
  const { text, type } = useAlert();
  if (text && type) {
    return (
      <div className="flex justify-center w-full z-50 fixed">
        <div
          className={`p-4 mb-4 text-sm rounded-lg ${type ==='error' ? 'text-red-800 bg-red-50' : 'text-green-800 bg-green-50'} absolute top-5 duration-75 transition-all`}
          role="alert"
          security={type}
        >
          <span className="font-medium flex items-center text-lg">
            <img src="/images/error_svg.svg" className="w-10 h-10" alt="" />
            {text}{" "}
          </span>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Alert;